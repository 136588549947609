<template>
        <div class="form-list">
            <el-form ref="_ExcelForm" :model="dataSource">
				<!-- <el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="CustomerName">
							<el-select v-model="dataSource.CustomerCode" collapse-tags placeholder="请选择" :maxlength="200"
								readonly @change="changeCustomer">
								<el-option v-for="item in dataSource.CustomerList" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<div>
						<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编码：</el-col>
						<el-col :span="7">
							<el-form-item prop="CustomerCode">
								<el-input v-model="dataSource.CustomerCode" :maxlength="30" :minlength="4" disabled
									placeholder="客户编码"></el-input>
							</el-form-item>
						</el-col>
					</div>
				</el-form-item> -->
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        模板选择：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item>
                            <el-select v-model="TemplateID" placeholder="选择模板" class="select-import">
                                <el-option v-for="item in templateList" :key="item.Key" :label="item.Value" :value="item.Key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
</template>

<script>
import sha from '../../../../scripts/sha.js';
    export default {
        data() {
            return {
                TemplateID: '',
                templateList: [],
                fileList: [],
                fileUrl:"omsapi/order/orderimporttemplate/exportflowtemplate"
            };
        },
        props: {
            dataSource: {}
        },
        mounted() {
            this.changeCustomer();
        },
        methods: {
			changeCustomer:function(){
				this.$ajax.send("omsapi/template/getexporttemplatebybusinesstype", "get", {
					customerCode: this.dataSource.CustomerCode,
					businessType: this.dataSource.busType
				}, (data) => {
					if (data.Result != null && data.Result != "") {
						this.templateList = data.Result;
					}
				
				});
			},
            loadTemplateListByBusinessType(value) {
                this.$ajax.send("omsapi/order/get/orderimporttemplate/bybusinesstype", "get", { ImportExportType: 2, BusinessType: value }, (data) => {
                    this.templateList = data.Result;
                    if (data.Result && data.Result.length > 0) {
                        this.statusInfo = '';
                        this.selectedTemplate = this.templateList[0].value;
                    }
                });
            },
            submit(options) {
                var urlType;
                if (this.TemplateID == "") {
                  //  urlType = this.dataSource.url + "?queryParam=" + this.dataSource.queryParam;
                }
                else
                {
					var data = this.dataSource.queryParam;
					if(!data.PageParams){
						data.PageParams={};
					}
					data.PageParams.templetID=this.TemplateID;
					var str = encodeURIComponent(JSON.stringify(data));
                    urlType =  this.dataSource.url + "?queryParam=" + str + "&token=" + sha.getToken1();
                }
                this.Utils.export(urlType);
				//var urlFile = "omsapi/customeraddress/exportfilerecieve?queryParam=" + str+ "&token=" + sha.getToken();
				//_this.Utils.export(urlFile);
                options.visible = false;
            },
        }

    }
</script>
<style scope>
    input[type="file"] {
        display: none;
    }

    .el-upload-list {
        width: 200px;
    }

    .el-select {
        width: 135px;
    }
</style>